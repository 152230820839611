import {Box, Flex, useTheme, VStack} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {TextG10, TextG12, TextG14} from '../../../components/typography';
import useAuth from '../../../hooks/useAuth';
import {getEventDate} from '../../../utils/date';
import {numberToCurrency} from '../../../utils/price';

const TicketDetails = ({
  event,
  ticket,
  background = 'white',
  total = '0',
  discountedTotal = '0',
}) => {
  const theme = useTheme();
  const {user} = useAuth();
  const navigate = useNavigate();
  const discountPerc = user?.activeMembershipPlan?.event_discount || 0;

  return (
    <Box
      w="100%"
      px={4}
      py={4}
      borderRadius="8px"
      background={background}
      border={`1px solid ${theme.colors.grey}`}>
      <VStack align="flex-start">
        <TextG10 color={theme.colors.grey} fontWeight="500">
          {getEventDate(event.start_date_time, event.end_date_time)}
        </TextG10>
        <TextG14 fontWeight="700">{ticket.name ? ticket.name : 'The Premium Lounge'}</TextG14>
        <Flex w="100%" justifyContent="space-between">
          <TextG12
            fontWeight="500"
            color={theme.colors.blue}
            onClick={() => {
              navigate(`/event/${event.id}`);
            }}>
            {event.title}
          </TextG12>
          {
            <Flex gap="5px" wordBreak="break-word" whiteSpace="nowrap">
              <TextG12 fontWeight="500" as={discountPerc && discountPerc > 0 ? 's' : ''}>
                {total != '0' ? total : numberToCurrency(ticket.price)}
              </TextG12>
              {discountPerc && discountPerc > 0 ? (
                <TextG12 fontWeight="500">
                  {discountedTotal != '0'
                    ? discountedTotal
                    : numberToCurrency(ticket.price - (ticket.price * discountPerc) / 100)}
                </TextG12>
              ) : null}
            </Flex>
          }
        </Flex>
      </VStack>
    </Box>
  );
};

export default TicketDetails;
