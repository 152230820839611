import React, {useEffect, useState} from 'react';
import {TextG14, TextG9} from '../../typography';
import {Box, Divider, Flex, HStack, IconButton, useTheme} from '@chakra-ui/react';
import useGlobalSearch from '../../../hooks/useGlobalSearch';
import BottomModalSheet, {BottomModalSheetRefProps} from '../../Modals/BottomModalSheet';
import QuickFilter from '../QuickFilter';
import {CONTENT_TYPE, FILTER_TYPES} from '../../../utils/GlobalSearch/enums';
import Icon from '../../Icon';
import AppliedFilters from '../AppliedFilters';
import ContentTypeFilters from '../FilterRenderer';
import {useQueryClient} from '@tanstack/react-query';
import {
  getContentTypeQuickFilters,
  getFiltersConfigForPopup,
} from '../../../utils/GlobalSearch/helpers';
import {FilterOption} from '../../../utils/GlobalSearch/types';

const getMarketplaceFilterColors = (filterName) => {
  switch (filterName) {
    case 'Food & Beverage':
      return {
        color: '#C53030',
        background: '#FFF5F5',
        border: '#FEB2B2',
      };
    case 'Our Favorites':
      return {
        color: '#D53F8C',
        background: '#FFF5F7',
        border: '#BCD7FF',
      };
    case 'Experiences':
      return {
        color: '#2058AB',
        background: '#E7F4FF',
        border: '#BCD7FF',
      };
    case 'Merch':
      return {
        color: '#DD6B20',
        background: '#FFFAF0',
        border: '#FBD38D',
      };
    case 'Services':
      return {
        color: '#25855A',
        background: '#F0FFF4',
        border: '#9AE6B4',
      };
    default:
      return {
        color: null,
        background: null,
      }; // Return null to use default QuickFilter colors
  }
};

type FiltersProps = {
  type: CONTENT_TYPE;
  sheetRef: React.MutableRefObject<BottomModalSheetRefProps | undefined>;
};

const Filters = ({type = CONTENT_TYPE.ALL, sheetRef}: FiltersProps) => {
  const {
    filters,
    setTypeSpecificFilter,
    getTypeSpecificFilter,
    resetTypeSpecificFilters,
    getNumOfAppliedFilters,
  } = useGlobalSearch();
  const theme = useTheme();

  const queryClient = useQueryClient();
  const contentTypeQuickFilters = getContentTypeQuickFilters(type, filters);
  const contentTypeFiltersConfig = getFiltersConfigForPopup(type as CONTENT_TYPE);
  let contentTypeAsyncOptionFilters = Object.keys(contentTypeFiltersConfig).filter(
    (filterKey) => !!contentTypeFiltersConfig[filterKey]?.fetchOptions
  );

  const [isFetchingOptions, setIsFetchingOptions] = useState(
    contentTypeAsyncOptionFilters?.length !== 0
  );

  useEffect(() => {
    Object.keys(contentTypeFiltersConfig).map((filterKey) => {
      if (contentTypeFiltersConfig[filterKey]?.fetchOptions) {
        queryClient
          .prefetchQuery({
            queryKey: [filterKey],
            queryFn: () => contentTypeFiltersConfig[filterKey].fetchOptions?.(),
          })
          .then(() => {
            contentTypeAsyncOptionFilters = contentTypeAsyncOptionFilters.filter(
              (val) => val !== filterKey
            );
            setIsFetchingOptions(contentTypeAsyncOptionFilters.length !== 0);
          });
      }
    });
  }, [type]);

  const renderQuickFilters = () =>
    Object.keys(contentTypeQuickFilters).map((filterConfigKey, i) => {
      if (contentTypeQuickFilters[filterConfigKey].type === FILTER_TYPES.MULTISELECT) {
        return renderQuickFiltersForMultiSelect(contentTypeFiltersConfig[filterConfigKey].key);
      }

      const colors = getMarketplaceFilterColors(contentTypeQuickFilters[filterConfigKey].label);

      return (
        <QuickFilter
          key={i}
          isFilterOn={getTypeSpecificFilter(type, filterConfigKey)}
          selectedColor={colors.color}
          selectedBackground={colors.background}
          selectedBorder={colors.border}
          onClick={() => {
            setTypeSpecificFilter(
              type,
              filterConfigKey,
              +!getTypeSpecificFilter(type, filterConfigKey)
            );
          }}>
          <TextG9 fontWeight="400">{contentTypeQuickFilters[filterConfigKey].label}</TextG9>
        </QuickFilter>
      );
    });

  const renderQuickFiltersForMultiSelect = (key: string) => {
    if (contentTypeFiltersConfig[key].fetchOptions && !isFetchingOptions) {
      const options = queryClient.getQueryData([key]) as FilterOption[];

      if (options) {
        return options?.map((option, i) => {
          const selectedValues = getTypeSpecificFilter(type, key) || [];
          const isFilterOn = selectedValues.includes(
            typeof selectedValues[0] === 'string' ? option.value.toString() : option.value
          );
          const excludeValue = (optionValue: string | number) =>
            selectedValues.filter(
              (value: string | number) =>
                value !== (typeof value === 'string' ? optionValue.toString() : optionValue)
            );
          const includeValue = (optionValue: string | number) => [
            ...selectedValues,
            typeof selectedValues[0] === 'string' ? optionValue.toString() : optionValue,
          ];

          const colors = getMarketplaceFilterColors(option.label);

          return (
            <QuickFilter
              key={option.value}
              isFilterOn={isFilterOn}
              onClick={() => {
                setTypeSpecificFilter(
                  type,
                  key,
                  isFilterOn ? excludeValue(option.value) : includeValue(option.value)
                );
              }}
              selectedColor={colors.color}
              selectedBackground={colors.background}
              selectedBorder={colors.border}>
              <TextG9 fontWeight="400">{option.label}</TextG9>
            </QuickFilter>
          );
        });
      }
    }
  };

  return (
    <>
      <BottomModalSheet
        ref={sheetRef}
        disableDrag={true}
        customHeader={<></>}
        triggerElement={
          <IconButton
            pos={'relative'}
            size={'lg'}
            borderRadius={'full'}
            backgroundColor={theme.colors.veryLightBlue}
            _focus={{backgroundColor: theme.colors.veryLightBlue}}
            _active={{backgroundColor: theme.colors.veryLightBlue}}
            _hover={{backgroundColor: theme.colors.veryLightBlue}}
            w="55px"
            h="40px"
            icon={
              <>
                <i className="fi fi-rr-filter"></i>
                {getNumOfAppliedFilters(type) ? (
                  <Box
                    as={'span'}
                    color={'white'}
                    position={'absolute'}
                    left={'18px'}
                    top={0}
                    right={0}
                    fontSize={'12px'}
                    bgColor={theme.colors.blue}
                    borderRadius={'full'}
                    zIndex={1}
                    p={'1px'}
                    w={'18px'}
                    h={'18px'}>
                    {getNumOfAppliedFilters(type)}
                  </Box>
                ) : null}
                <Icon style={{fontSize: '10px', marginLeft: '8px'}} iconName="fi-rr-angle-down" />
              </>
            }
            aria-label={'filters'}
          />
        }>
        <>
          <Flex alignItems={'center'} justifyContent={'space-between'} p={6}>
            <HStack>
              <i className="fi fi-rr-filter"></i>
              <TextG14 fontWeight={'bold'}>Filter {type}</TextG14>
            </HStack>
            <TextG14
              color={theme.colors.blue}
              onClick={() => {
                resetTypeSpecificFilters(type as CONTENT_TYPE);
                sheetRef.current?.setState(false);
              }}>
              Reset
            </TextG14>
          </Flex>
          <Divider />
          <ContentTypeFilters type={type as CONTENT_TYPE} sheetRef={sheetRef} />
        </>
      </BottomModalSheet>
      <Divider orientation="vertical" h={'38px'} mr={1} />
      <Box display={'flex'} gap={1}>
        {renderQuickFilters()}
        {!isFetchingOptions ? <AppliedFilters type={type}></AppliedFilters> : null}
      </Box>
    </>
  );
};

export default Filters;
