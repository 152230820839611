import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Flex, Box, useTheme, Image} from '@chakra-ui/react';
import {TextG11, TextG8} from '../typography';
import useAuth from '../../hooks/useAuth';
import Logo from '../../assets/logos/logo_48x48.png';

const homeQuickLinksData = [
  {
    value: 'event',
    link: '/search/event',
    label: 'Walkabout Events',
    color: '#5976BC',
    icon: Logo,
  },
  {
    value: 'places',
    link: '/search/place',
    label: 'Places',
    color: '#92217B',
  },
  {
    value: 'group_chat',
    link: `/lifestyle/chat`,
    label: 'Group Chat',
    color: '#7D3EEE',
  },
  {
    value: 'Marketplace',
    link: `/marketplace`,
    label: 'Marketplace',
    color: '#BD60D4',
  },
  {
    value: 'partners',
    link: `/search/partner`,
    label: 'Partners',
    color: '#54937C',
  },
  {
    value: 'posts',
    link: `/search/post`,
    label: 'Posts',
    color: '#FF6813',
  },
];

const HomeQuickLinks = () => {
  const navigate = useNavigate();
  const {user} = useAuth();
  const theme = useTheme();

  return (
    <>
      {
        <Flex
          w="100%"
          flexWrap="wrap"
          justifyContent="center"
          id="quick-links-tooltip"
          // templateRows="repeat(2, 1fr)" // 2 rows
          // templateColumns="repeat(3, 1fr)" // 3 columns
          px={0}
          mx={0}
          rowGap={3}
          columnGap={2.5}
          mb={3}
          position="relative">
          {homeQuickLinksData.map((item, i) => {
            return (
              <Flex
                flexGrow={1}
                flexBasis={1}
                flexDir="column"
                pos="relative"
                minW="102px"
                background="white"
                border="2px solid #E3EEEE"
                //boxShadow="0px 0px 20px 0px #00000014"
                key={i}
                textAlign="center"
                align="center"
                justify="center"
                gap={0}
                height="44px"
                py={2}
                px={4}
                borderRadius="10px"
                onClick={() => {
                  navigate(item.link);
                }}>
                <Flex alignItems="center" gap="3px">
                  {/* {item.icon && <Image w="12px" h="12px" src={item.icon} />} */}
                  <TextG11 fontWeight="500" color={item.color} overflowWrap="break-word">
                    {item.label}
                  </TextG11>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      }
    </>
  );
};

export default HomeQuickLinks;
