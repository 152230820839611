import {
  fetchPlaceTypes,
  fetchTagsForSearch,
  fetchPassTypesForSearch,
  fetchTagCategoriesForSearch
} from "../../endpoints/api";
import {TAG_TYPE} from "./enums";

export const fetchTags = async () => {
  const tags = await fetchTagsForSearch();

  if(!tags) return [];

  return [
    ...tags.data.lifestyleGroups.map((lifestyleGroup) => ({
      value: lifestyleGroup.id,
      label: `#${lifestyleGroup.name}`,
      type: TAG_TYPE.GOALS,
    })),
    ...tags.data.topicOfInterests.map((topicOfInterest) => ({
      value: topicOfInterest.id,
      label: `#${topicOfInterest.name}`,
      type: TAG_TYPE.TOPICS,
    })),
  ];
};

export const fetchTagCategories = async () => {
  const tagCategories = await fetchTagCategoriesForSearch();

  if(!tagCategories) return [];

  return [
    ...tagCategories.data.map((tagCategory) => ({
      value: tagCategory.id,
      label: tagCategory.name,
    }))
  ];
}

export const fetchPlaceCategories = async () => {
  const { data: placeTypes} = await fetchPlaceTypes({ withoutPagination: true });

  if(!placeTypes) return [];

  return [
    ...placeTypes.data.map((placeType) => ({
      value: placeType.id,
      label: placeType.name,
    }))
  ];
};

export const fetchPassTypes = async () => {
  const passTypes = await fetchPassTypesForSearch();

  if(!passTypes) return [];

  return passTypes.data.map((passType) => ({
    value: passType.id,
    label: `${passType.name} Pass`,
  }));
}
