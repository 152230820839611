import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import ActiveRedemptionCards from '../../components/ActiveRedemptionCards';
import {H3, TextG12} from '../../components/typography';
import useAuth from '../../hooks/useAuth';
import Bookmarks from '../Bookmarks';
import {startCase, camelCase} from 'lodash';
import MyPasses from '../MyPasses';
import MyTickets from '../MyTickets';
import Vouchers from './Vouchers';
import TransactionHistory from './TransactionHistory';

const WALLET_TAB = ['Vouchers', 'Passes', 'Tickets', 'Receipts'];

const Wallet = () => {
  const theme = useTheme();
  const {user} = useAuth();

  let [searchParams, setSearchParams] = useSearchParams();

  const getDefaultTab = () => {
    const tabFromParams = searchParams.get('subtab');
    const tabIndex = WALLET_TAB.indexOf(startCase(camelCase(tabFromParams || WALLET_TAB[0])));
    return tabIndex >= 0 ? tabIndex : 0; // Default to the first tab if not found
  };

  const [tabIndex, setTabIndex] = useState(getDefaultTab());

  // useEffect(() => {
  //   const newTabIndex = getDefaultTab();
  //   setTabIndex(newTabIndex);
  // }, [searchParams]);

  useEffect(() => {
    // Clone existing search params
    const currentParams = new URLSearchParams(searchParams);

    // Update or add the 'subtab' parameter
    currentParams.set('subtab', WALLET_TAB[tabIndex]);

    // Set the updated search params back
    setSearchParams(currentParams);
  }, [tabIndex, searchParams]);

  return (
    <Tabs
      variant="enclosed"
      outline={'none !important'}
      index={tabIndex}
      onChange={(index) => setTabIndex(index)}
      isFitted
      isLazy
      lazyBehavior="keepMounted">
      <Flex background="#F7FAFC" pt={2} flexDir="column" w="100vw" ml={-4} justifyContent="center">
        <TabList w="100vw" justifyContent="center" px={1} border="none">
          {WALLET_TAB.map((tab, index) => (
            <Tab
              border="1px solid transparent"
              borderBottom="none"
              transition="all 0.3s ease"
              px={0}
              _selected={{
                border: '1px solid #CBD5E0 !important',
                borderWidth: '0px',
                background: 'white',
                borderBottomColor: 'transparent !important',
                borderBottomWidth: '0px !important',
              }}
              key={index}>
              <TextG12
                fontWeight={tabIndex === index ? 700 : 500}
                color={tabIndex === index ? theme.colors.blue : '#718096'}>
                {tab}
              </TextG12>
            </Tab>
          ))}
        </TabList>
      </Flex>

      <TabPanels zIndex="1000" background="white">
        <TabPanel p={0}>
          <Vouchers />
        </TabPanel>
        <TabPanel p={0}>
          <MyPasses />
        </TabPanel>
        <TabPanel p={0}>
          <MyTickets />
        </TabPanel>
        <TabPanel p={0}>
          <TransactionHistory />
        </TabPanel>
        {/* <TabPanel p={0}>
            <MyDiscounts />
          </TabPanel>
          <TabPanel p={0}>
            <Wallet />
          </TabPanel>
          {/* <TabPanel p={0}>
            <MyPasses />
          </TabPanel>
          <TabPanel p={0}>
            <MyTickets />
          </TabPanel> 
          <TabPanel p={0}>
            <Bookmarks />
          </TabPanel>
          */}
      </TabPanels>
    </Tabs>
  );
};

export default Wallet;
