import React from 'react';
import {Stack, Flex, useTheme, Box} from '@chakra-ui/react';
import {Offer} from '../../types/acrticle';
import TopicsOfInterest from '../TopicsOfInterest';
import {useSearchParams} from 'react-router-dom';
import {TextG14, TextG12, H3} from '../../components/typography';

const Summary = ({item, showBookmark = true}: {item: Offer; showBookmark?: boolean}) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  //   useEffect(() => {
  //     if (!!searchParams.get('bookmark') && item && !isBookmarked) {
  //       handleOnBookmarkClick(null);
  //     }
  //   }, [searchParams, item]);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '1.5rem',
    }),
    [theme]
  );

  return (
    <>
      {item.summary && (
        <Box
          className="inner-content"
          dangerouslySetInnerHTML={{__html: item.summary}}
          w="100%"
          px={5}
          mt={14}
          mb={4}></Box>
      )}
    </>
  );
};

export default Summary;
