import {
  Flex,
  Box,
  Spinner,
  Button,
  Collapse,
  useDisclosure,
  useTheme,
  AbsoluteCenter,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import {TextA24, TextG10, TextG12, TextG14, TextG16} from '../../../components/typography';
import {useFetchItemTransactions} from '../../../hooks/useItemTransactions';
import {numberToCurrency} from '../../../utils/price';

// TransactionCard component to avoid using hooks inside the loop
const TransactionCard = ({transaction}: {transaction: any}) => {
  const theme = useTheme();
  const itemType = transaction.items[0]?.item_type;
  const itemTitle =
    itemType === 'event_ticket' ? 'Event Ticket' : transaction.meta?.item_title || 'No Title';

  // Manage disclosure state outside of loop
  const {isOpen, onToggle} = useDisclosure();

  const quantity = transaction.items[0]?.quantity || 1;
  const price = transaction.items[0]?.price || 0;
  const totalPrice = quantity * price;
  const discount = transaction?.discount || 0;
  const discountAmount = (totalPrice * discount) / 100;
  const discountedPrice = totalPrice - discountAmount;

  // Check if the transaction used a pass (user_pass_id exists)
  const userPassUsed = transaction?.user_pass_id;

  return (
    <Box
      key={transaction.id}
      borderWidth="1px"
      borderRadius="md"
      p="10px"
      mb="10px"
      boxShadow="sm"
      bg="white"
      cursor="pointer"
      onClick={onToggle}>
      {/* Transaction Summary */}
      <Flex justify="space-between" align="center" color={theme.colors.lightBlack}>
        <TextG16 fontWeight="bold">{itemTitle}</TextG16>
        <TextG14 whiteSpace="nowrap">{numberToCurrency(discountedPrice || totalPrice)}</TextG14>
      </Flex>

      {/* Conditional: Quantity Info (above Price Breakdown) */}
      {quantity > 0 && (
        <Flex my="4px" justify="space-between">
          <TextG12 fontWeight="bold">Quantity</TextG12>
          <TextG12>{quantity}</TextG12>
        </Flex>
      )}

      {/* Date Info */}
      <TextG10 fontSize="sm" color={theme.colors.lightBlack}>
        {dayjs.utc(transaction.created_at).local().format('MMM. D [at] h:mmA')}
      </TextG10>

      {/* Show Detailed Info on Click */}
      <Collapse in={isOpen}>
        <Box mt="12px" borderTop="1px" borderColor="gray.200" pt="10px">
          <TextG10 fontWeight="bold" mb="4px">
            Price Breakdown
          </TextG10>
          <Flex justify="space-between">
            <TextG10>Price per Item</TextG10>
            <TextG10>{numberToCurrency(price)}</TextG10>
          </Flex>
          <Flex justify="space-between">
            <TextG10>Total Price</TextG10>
            <TextG10>{numberToCurrency(totalPrice)}</TextG10>
          </Flex>

          {/* Move discount info here */}
          {discount > 0 && (
            <Flex justify="space-between">
              <TextG10 fontWeight="bold">Discount</TextG10>
              <TextG10>
                {numberToCurrency(discountAmount)} ({discount}%)
              </TextG10>
            </Flex>
          )}

          {/* Conditional: Pass Info */}
          {userPassUsed && (
            <Flex justify="space-between">
              <TextG10 fontWeight="bold">Pass Used</TextG10>
              <TextG10>Covered by pass</TextG10>
            </Flex>
          )}

          {/* Cash Paid and Points Spent */}
          {transaction.cash_paid && (
            <Flex justify="space-between">
              <TextG10 fontWeight="bold">Cash Paid</TextG10>
              <TextG10>{numberToCurrency(transaction.cash_paid)}</TextG10>
            </Flex>
          )}

          {transaction.points_spent && (
            <Flex justify="space-between">
              <TextG10 fontWeight="bold">Points Spent</TextG10>
              <TextG10>{transaction.points_spent.toLocaleString() || '0'}</TextG10>
            </Flex>
          )}
        </Box>
      </Collapse>

      {/* Toggle Button for Expand/Collapse */}
      <Button variant="link" size="sm" onClick={onToggle} w="100%" mt="10px" textAlign="center">
        {isOpen ? 'Show Less' : 'Show More'}
      </Button>
    </Box>
  );
};

const TransactionHistory = () => {
  const theme = useTheme();
  const {data, isLoading} = useFetchItemTransactions();
  const transactions = data?.data?.transactions;

  return (
    <Flex direction="column" my="20px" px="10px">
      {isLoading ? (
        <AbsoluteCenter>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={theme.colors.lightGrey}
            color={theme.colors.lightBlue}
            size="lg"
          />
        </AbsoluteCenter>
      ) : transactions?.length ? (
        transactions.map((transaction, i) => (
          <TransactionCard key={transaction.id || i} transaction={transaction} />
        ))
      ) : (
        <Flex justify="center" align="center" h="100px">
          <TextA24>No transaction receipts found</TextA24>
        </Flex>
      )}
    </Flex>
  );
};

export default TransactionHistory;
