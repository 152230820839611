import React, {useState} from 'react';
import {Box, Center, Divider, Flex, HStack, Spinner, useTheme, VStack} from '@chakra-ui/react';
import {TextG12, TextG14} from '../../../components/typography';
import {
  dollarsToPoints,
  getPointsToDollarRatio,
  numberToCurrency,
  renderPointsToDollars,
} from '../../../utils/price';
import Header from './Header';
import {StyledCheckCircleFilled} from '../../../constants/eventOptions';
import {BlueButton} from '../../../components/button';
import TicketDetails from '../../TicketSelect/TicketDetailsContainer/TicketDetails';
import {useSelector} from 'react-redux';
import {getRandomElements} from '../../../utils/random';
import {purchaseEventTicket, purchaseItem} from '../../../endpoints/api';
import {ToastMessage} from '../../../components/toast';
import {useNavigate} from 'react-router-dom';
import {EventRSVPStatus, useSetAttendance} from '../../../hooks/useEvent';
import {Event} from '../../../types/acrticle';
import OfferCard from '../../../components/Articles/Offers/OfferCard';
import {queryClient} from '../../../App';

const transformString = (str) =>
  str
    .split(/[_\s]+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

const PaymentMethods = ({appliedIds, appliedPass}) => (
  <Box w="100%">
    <TextG14 my="10px" fontWeight="700">
      Payment Method
    </TextG14>
    {appliedIds.map((id) => (
      <Box
        key={id}
        background={id === 'card_payment' ? '#FEF4E6' : '#E0F3E3'}
        px={4}
        py={3}
        borderRadius="8px"
        mb={2}>
        <TextG12 display="inline-flex" gap="10px">
          <StyledCheckCircleFilled w="16px" h="16px" fill="green" />
          {id === 'user_pass' ? `${appliedPass.pass.title} Pass` : transformString(id)}
        </TextG12>
      </Box>
    ))}
  </Box>
);

const SummarySection = ({
  originalTotal,
  passDiscountAmount,
  numPointsApplied,
  membershipDiscountPercentage,
  membershipDiscountAmount,
  cardPaymentAmount,
  item,
  type,
  pointCost,
  retailCost,
}) => {
  const theme = useTheme();

  return (
    <VStack w="100%" align="flex-start" gap="5px">
      <TextG14 fontWeight="700">Summary</TextG14>
      <Divider color={theme.colors.lighterGrey} />
      <HStack justify="space-between" w="100%">
        <TextG12>Total Amount:</TextG12>
        <TextG12 color={theme.colors.red}>
          {!item.item_payment_cc && !item.item_payment_pass && item.item_payment_points
            ? `${dollarsToPoints(originalTotal, getPointsToDollarRatio(item)).toLocaleString()} Points`
            : numberToCurrency(originalTotal)}
        </TextG12>
      </HStack>
      {membershipDiscountPercentage > 0 && (
        <HStack justify="space-between" w="100%">
          <TextG12>{`Membership Discount (${membershipDiscountPercentage}%):`}</TextG12>
          <TextG12 color="#329241">
            {!item.item_payment_cc && !item.item_payment_pass && item.item_payment_points
              ? `-${dollarsToPoints(membershipDiscountAmount, getPointsToDollarRatio(item)).toLocaleString()} Points`
              : `-${numberToCurrency(membershipDiscountAmount)}`}
          </TextG12>
        </HStack>
      )}
      {passDiscountAmount > 0 && (
        <HStack justify="space-between" w="100%">
          <TextG12>Pass:</TextG12>
          <TextG12>-{numberToCurrency(passDiscountAmount)}</TextG12>
        </HStack>
      )}
      {numPointsApplied > 0 && (
        <HStack justify="space-between" w="100%">
          <TextG12>{`Walkabout Points (${numPointsApplied?.toLocaleString()}):`}</TextG12>
          <TextG12>
            {!item.item_payment_cc && !item.item_payment_pass && item.item_payment_points
              ? `-${numPointsApplied.toLocaleString()} Points`
              : `-${renderPointsToDollars(numPointsApplied, pointCost)}`}
          </TextG12>
        </HStack>
      )}

      <Divider color={theme.colors.lighterGrey} />
      <HStack justify="space-between" w="100%">
        <TextG12>
          {`You owe ${cardPaymentAmount > 0 ? `(Amount to be charged to card)` : ``}:`}
        </TextG12>
        <TextG12 color={cardPaymentAmount <= 0 ? '#329241' : theme.colors.red}>
          {cardPaymentAmount
            ? numberToCurrency(cardPaymentAmount)
            : !item.item_payment_cc && !item.item_payment_pass && item.item_payment_points
            ? '0 Points'
            : '$0.00'}
        </TextG12>
      </HStack>
    </VStack>
  );
};

interface OrderSummaryProps {
  setShowSummary: (show: boolean) => void;
  // ticket?: any;
  // event?: Event;
  // pass?: any;
  item?: any;
  event?: Event;
  type?: string;
  pointCost?: any;
  retailCost?: any;
}

const OrderSummary = ({
  setShowSummary,
  item,
  event,
  type,
  pointCost,
  retailCost,
}: OrderSummaryProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {mutateAsync} = useSetAttendance();
  const [stripeLoading, setStripeLoading] = useState(false);

  const {
    appliedIds,
    qty,
    total,
    numPointsApplied,
    passDiscountAmount,
    cardPaymentAmount,
    willcallName,
    appliedPass,
    membershipDiscountPercentage,
    membershipDiscountAmount,
    originalTotal,
  } = useSelector((state: any) => state.checkout);

  const handlePaymentClicked = async () => {
    setStripeLoading(true);
    if (type == 'ticket' && event) {
      const tickets = getRandomElements(event.available_tickets, qty)?.map((ticket) => item.id);

      try {
        const {data} = await purchaseEventTicket({
          event_ticket_ids: [...tickets],
          points: numPointsApplied?.toFixed(0),
          cash: cardPaymentAmount?.toFixed(2),
          willcall_name: willcallName,
          ...(appliedPass && {pass_id: appliedPass.id}),
          success_url: `${window.location.origin}/tickets/${event.id}?success=1`,
          cancel_url: window.location.href,
        });

        if (!data.status && data.message) {
          ToastMessage({
            status: 'error',
            text: data.message,
          });
          setStripeLoading(false);
          return;
        }

        if (data && data?.url) {
          window.location.href = data.url;
          return;
        } else {
          navigate(`/tickets/${event.id}?success=1`, {replace: true});
          if (event.rsvp_status.status_value == EventRSVPStatus.NO_STATUS) {
            mutateAsync({
              eventId: event.id,
              prevStatus: event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS,
              status: EventRSVPStatus.GOING,
              avoidDispatchRSVPModalPopupActions: false,
            });
          }
        }
      } catch (e: any) {
        if (e && e.message) {
          ToastMessage({
            status: 'error',
            text: e?.message,
          });
          setStripeLoading(false);
        }
      }
    } else if (type == 'item') {
      const params = {
        item_uuid: item.uuid,
        quantity: qty,
        points: numPointsApplied?.toFixed(0) || 0,
        cash: cardPaymentAmount?.toFixed(2) || 0,
        ...(appliedPass && {pass_id: appliedPass.id}),
        success_url: `${window.location.origin}/my-stuff?tab=Wallet&subtab=Vouchers`,
        cancel_url: window.location.href,
      };

      try {
        const {data} = await purchaseItem(params);

        queryClient.invalidateQueries(['user_vouchers']);

        if (!data.status && data.message) {
          ToastMessage({
            status: 'error',
            text: data.message,
          });
          setStripeLoading(false);
          return;
        }

        // if (data && data?.url) {
        //   window.location.href = data.url+`&transaction=${data.transaction.id}`;
        //   return;
        // } else {
        //   navigate(`/item/${item.id}?success=1`, {replace: true});
        // }

        setStripeLoading(false);
        navigate(`/my-stuff?tab=Wallet&subtab=Vouchers&transaction=${data.transaction.id}`, {
          replace: true,
        });
      } catch (e: any) {
        if (e && e.message) {
          ToastMessage({
            status: 'error',
            text: e?.message,
          });
          setStripeLoading(false);
        }
      }
    }
    // } else if (type pass) {
    //   const purchasedPasses = await purchasePass({
    //     passId: pass.id,
    //     quantity: qty,
    //     successUrl: location.origin + `/pass/${pass.id}`,
    //     cancelUrl: location.origin + '/my-stuff?tab=Passes',
    //   });

    //   const stripeLink = purchasedPasses.data?.link;

    //   window.location.href = stripeLink;
    // }
  };

  if (stripeLoading) {
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  return (
    <>
      <Header setShowSummary={setShowSummary} />
      <VStack px={4} align="flex-start">
        <HStack justify="space-between" w="100%">
          <TextG14 fontWeight="700">{`Type: ${type == 'ticket' ? 'Tickets' : 'Item'}`}</TextG14>
          <TextG14 fontWeight="700">{`Quantity: ${qty}`}</TextG14>
        </HStack>
        {
          type == 'ticket' ? (
            <TicketDetails
              event={event}
              ticket={item}
              background="#F4F7F8"
              total={numberToCurrency(originalTotal)}
              discountedTotal={numberToCurrency(total)}
            />
          ) : (
            <OfferCard offer={item} />
          )
          //   (
          //   <PassCard
          //     pass={pass}
          //     showGetMore={false}
          //     showArrow={false}
          //     expires_at={dayjs.utc().add(1, 'year').format('YYYY-MM-DDTHH:mm')}
          //   />
          // )
        }
        <PaymentMethods appliedIds={appliedIds} appliedPass={appliedPass} />
        <SummarySection
          originalTotal={originalTotal}
          passDiscountAmount={passDiscountAmount}
          numPointsApplied={numPointsApplied}
          membershipDiscountPercentage={membershipDiscountPercentage}
          membershipDiscountAmount={membershipDiscountAmount}
          cardPaymentAmount={cardPaymentAmount}
          item={item}
          type={type}
          pointCost={pointCost}
          retailCost={retailCost}
        />
      </VStack>
      <Flex w="100%" p={4} pos="fixed" bottom="0" background="white">
        <BlueButton
          w="100%"
          onClick={handlePaymentClicked}
          _hover={{backgroundColor: theme.colors.blue}}>
          <TextG14>{cardPaymentAmount > 0 ? 'Continue to Card Payment' : 'Place Order'}</TextG14>
        </BlueButton>
      </Flex>
    </>
  );
};

export default OrderSummary;
