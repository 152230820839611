import {
  VStack,
  Image,
  Flex,
  Center,
  Spinner,
  useTheme,
  AbsoluteCenter,
  Box,
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useFetchEvent} from '../../hooks/useEvent';
import Header from './Header';
import TicketDetailsContainer from './TicketDetailsContainer';
import TicketQuantityContainer from './TicketQuantityContainer';
import WillCallSelect from './WillCallSelect';
import Background from '../../assets/images/ticketBG.png';
import {BlueButton} from '../../components/button';
import {Event} from '../../types/acrticle';
import {TextA18, TextG12} from '../../components/typography';
import {TICKET_TYPES} from '../../types/ticket_types';
import useAuth from '../../hooks/useAuth';
import {useFetchActivePasses} from '../../hooks/useUserPasses';
import {filter, map, some} from 'lodash';
import ExternalLink from '../../components/ExternalLink';
import useCountdown from '../../hooks/useCountdown';

const TicketSelect = () => {
  const navigate = useNavigate();
  const {eventId} = useParams();
  const fetchEvent = useFetchEvent(Number(eventId));
  const event: Event = fetchEvent.data?.data;
  const [quantity, setQuantity] = useState(1);
  const isLoading = fetchEvent.isLoading;
  const [ticket, setTicket] = useState<any>();
  const theme = useTheme();
  const {user} = useAuth();
  const {data} = useFetchActivePasses(!!user);
  const {endPassed} = useCountdown(event);

  const fetchActivePasses = useFetchActivePasses();
  const userPasses = fetchActivePasses.data?.data;

  const userPassesIds = map(
    filter(data?.data, (item) => item.pass !== null),
    (item) => item.pass?.type_id
  );
  const passIds = map(event?.pass_types, (item) => item.id);

  const userHasPass = passIds.some((id) => userPassesIds.includes(id));

  const memberHasNoPass = user?.hasActiveMembership && !userHasPass;

  useEffect(() => {
    if (event && event?.available_tickets && event?.available_tickets[0]) {
      setTicket({...event.available_tickets[0]});
    }
  }, [event, event?.available_tickets]);

  const eligibleUserPasses = filter(userPasses, (userPass) =>
    some(event?.pass_types, (passType) => passType.id === userPass?.pass?.type?.id)
  );

  if (isLoading)
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <VStack overflow="hidden" maxH="100%">
      <Header event={event} />
      {!ticket || endPassed ? (
        <AbsoluteCenter h="30vh" px={12} w="90vw">
          <TextA18 textAlign="center">There are no more tickets from Walkabout available.</TextA18>
          {event?.tickets_url && (
            <TextA18 textAlign="center" mt="25px">
              {'You may still be able to get tickets '}
              <b>
                <ExternalLink isExternal href={event.tickets_url} color={theme.colors.lighterBlue}>
                  here
                </ExternalLink>
              </b>
            </TextA18>
          )}
        </AbsoluteCenter>
      ) : memberHasNoPass ? (
        <AbsoluteCenter h="30vh" px={12} w="90vw">
          <TextA18 textAlign="center">You Need an Experience Pass To Purchase This Ticket</TextA18>
          {event?.tickets_url && (
            <TextA18 textAlign="center" mt="25px">
              {'You may still be able to get tickets '}
              <b>
                <ExternalLink isExternal href={event.tickets_url} color={theme.colors.lighterBlue}>
                  here
                </ExternalLink>
              </b>
            </TextA18>
          )}
        </AbsoluteCenter>
      ) : (
        <>
          <TicketDetailsContainer event={event} ticket={ticket} />
          {
            <TicketQuantityContainer
              event={event}
              ticket={ticket}
              quantity={quantity}
              setQuantity={setQuantity}
            />
          }
          {event.ticket_type_id == TICKET_TYPES.WILL_CALL ? <WillCallSelect /> : null}
        </>
      )}
      {user?.hasActiveMembership &&
        eligibleUserPasses &&
        eligibleUserPasses.length > 0 &&
        ticket &&
        !endPassed && (
          <Flex alignItems="baseline">
            <i
              className="fi fi-br-check"
              style={{fontSize: '10px', color: '#329241', marginRight: '0.3rem'}}></i>
            <TextG12 fontWeight="700" color="#329241">
              You can use a Pass during Checkout
            </TextG12>
          </Flex>
        )}
      <Image pos="absolute" bottom="230px" zIndex={-1} w="100%" src={Background} />
      {ticket && !memberHasNoPass && (
        <Flex
          background="white"
          pos="fixed"
          bottom="0px"
          justify="center"
          margin="0px !important"
          px={4}
          py={6}
          w="100%">
          <BlueButton
            w="100%"
            onClick={() =>
              navigate(`/checkout?qty=${quantity}&event=${event.id}&type=ticket`, {replace: true})
            }>
            Continue to Checkout
          </BlueButton>
        </Flex>
      )}
    </VStack>
  );
};

export default TicketSelect;
