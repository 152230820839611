import React from 'react';
import {Box} from '@chakra-ui/react';
import 'swiper/css';
import 'swiper/css/pagination';
import ItemCarousel from '../../components/ItemCarousel';
import OfferCard from '../../components/Articles/Offers/OfferCard';
import {Offer} from '../../types/acrticle';
import {SkeletonLoader} from '../../components/SkeletonLoader';
import {useInfiniteGlobalSearchWithParams} from '../../hooks/useFetchGlobalSearch';

const Carousel: React.FC = () => {

  const {data, isLoading} = useInfiniteGlobalSearchWithParams({
    term: '',
    type: 'offer',
    sort: {
      by: 'ends_at',
      direction: 'asc',
    },
    featured: '1',
  });

  const offers = data?.pages[0]?.data?.data;
  const offersIsLoading = isLoading;

  return (
    <Box my={6} mx={2}>
      {offersIsLoading ? (
        <SkeletonLoader cardHeight="125px" hasTitle={false} cardBorderRadius="12px" />
      ) : (
        <ItemCarousel
          topRightItem={<></>}
          swiperProps={{
            autoHeight: true,
            slidesPerGroup: 1,
            slidesPerView: 1,
            items: offers,
            render: (item) => {
              return <OfferCard height="97.5%" offer={item as Offer} />;
            },
          }}
        />
      )}
    </Box>
  );
};

export default Carousel;
