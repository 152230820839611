import React, {useState} from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useTheme,
} from '@chakra-ui/react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {Autoplay, FreeMode, Pagination, Lazy} from 'swiper';
import styled from 'styled-components';
import {useItemRedeemVoucher} from '../../../hooks/useItemVouchers';
import {useNavigate} from 'react-router-dom';
import RedemptionConfirmationPrompt from './RedemptionConfirmationPrompt'; // Import the prompt modal
import dayjs from 'dayjs';
import {TextA16, TextA18, TextA24, TextG10, TextG12, TextG14} from '../../../components/typography';

const StyledModalContent = styled(Box)`
  .chakra-modal__content-container {
    z-index: 1;
  }
`;

const StyledSwiper = styled(Swiper)`
  z-index: 0;
  margin-top: 0px !important;
  .swiper-pagination {
    position: relative !important;
    bottom: 0px !important;
    margin-top: 15px;
  }
`;

interface VoucherDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  group: {
    transactionId?: string;
    itemName: string;
    picture?: string;
    quantity: number;
    placeName?: string;
    pickupLocation?: string;
    pickupId?: string;
    vouchers?: any;
  };
  onRedemptionOpen: (voucher: any) => void; // Callback to open redemption modal
}

const VoucherDetailsModal: React.FC<VoucherDetailsModalProps> = ({
  isOpen,
  onClose,
  group,
  onRedemptionOpen,
}) => {
  const theme = useTheme();
  const {itemName, picture, quantity, pickupLocation} = group;
  const {mutateAsync} = useItemRedeemVoucher();
  const navigate = useNavigate();

  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState<any>();

  const handleOpenPrompt = (voucher) => {
    setSelectedVoucher(voucher);
    setIsPromptOpen(true);
  };

  // Callback for when the user confirms redemption
  const handleConfirmRedeem = async () => {
    if (selectedVoucher && selectedVoucher.id) {
      try {
        await mutateAsync(selectedVoucher.id);
        setIsPromptOpen(false);
        // Trigger the redemption modal to show once voucher is redeemed
        onRedemptionOpen(selectedVoucher);
      } catch (error) {
        console.error('Error redeeming voucher:', error);
      }
    }
  };

  return (
    <>
      {/* Main Voucher Modal */}
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay zIndex={0} />
        <StyledModalContent>
          <ModalContent pb={24} mt={12}>
            <ModalHeader textAlign="center">Voucher</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <StyledSwiper
                autoHeight={true}
                spaceBetween={10}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                modules={[Autoplay, Pagination, FreeMode, Lazy]}>
                {group.vouchers?.map((voucher, index) => (
                  <SwiperSlide style={{padding: '10px'}} key={`voucher-${index + 1}`}>
                    <Flex
                      direction="column"
                      align="center"
                      boxShadow={theme.shadows.med}
                      p="20px"
                      borderRadius="12px">
                      {/* Voucher Image */}
                      <Box
                        width="150px"
                        height="150px"
                        borderRadius="lg"
                        overflow="hidden"
                        bg="gray.100"
                        display="flex"
                        alignItems="center"
                        justifyContent="center">
                        {picture ? (
                          <Image src={picture} alt={itemName} boxSize="100%" objectFit="cover" />
                        ) : (
                          <TextG10>No Image</TextG10>
                        )}
                      </Box>

                      {/* Voucher Title */}
                      <TextA16 fontWeight="900" textAlign="center" mt={4}>
                        {itemName}
                      </TextA16>

                      {/* Pickup Location */}
                      {pickupLocation && (
                        <TextG10
                          fontWeight="700"
                          mt={1}
                          onClick={() => navigate(`/place/${group.pickupId}`)}>
                          Pickup Location:{' '}
                          <span style={{color: theme.colors.blue, fontWeight: '500'}}>
                            {pickupLocation}
                          </span>
                        </TextG10>
                      )}

                      {/* Divider */}
                      <Divider my={4} width="100%" />

                      {/* Voucher Details */}
                      <Box width="100%" px={4}>
                        <TextG14 fontWeight="bold" mb={2}>
                          DETAILS
                        </TextG14>
                        <Flex justify="space-between" mb={1} alignItems="center">
                          <TextG12 whiteSpace="nowrap" fontWeight="700">
                            Item:
                          </TextG12>
                          <TextG12 textAlign="right">{itemName}</TextG12>
                        </Flex>
                        <Flex justify="space-between" mb={1}>
                          <TextG12 fontWeight="700">Quantity:</TextG12>
                          <TextG12>{`${index + 1} of ${quantity}`}</TextG12>
                        </Flex>
                      </Box>

                      {voucher.redeemed_at ? (
                        <TextA24 mt={2} textAlign="center" color="red">
                          {`Redeemed on ${dayjs
                            .utc(voucher.redeemed_at)
                            .local()
                            .format('MMM. D [at] h:mmA')}`}
                        </TextA24>
                      ) : (
                        <Button
                          background={theme.colors.rose}
                          color="white"
                          width="100%"
                          mt={4}
                          onClick={() => handleOpenPrompt(voucher)}>
                          Redeem Voucher
                        </Button>
                      )}
                    </Flex>
                  </SwiperSlide>
                ))}
              </StyledSwiper>

              {/* Redemption Instructions */}
              <Box mt={4} px={4}>
                <TextA18 fontWeight="bold" mb={2} color={theme.colors.lightBlack}>
                  Redemption Instructions
                </TextA18>
                {pickupLocation && group.pickupId && (
                  <TextG14 fontWeight="bold" onClick={() => navigate(`/place/${group.pickupId}`)}>
                    Head to <span style={{color: theme.colors.blue}}>{pickupLocation}</span>
                  </TextG14>
                )}
                <TextG12 fontSize="sm" color={theme.colors.lightBlack}>
                  These items must be picked up at the location linked above. Once you reach the
                  destination, click the 'Redeem Voucher' button below to exchange for the item. You
                  will have 5 minutes to show the person ringing up your order the final page that
                  contains the item and your code (if required).
                </TextG12>
              </Box>
            </ModalBody>
          </ModalContent>
        </StyledModalContent>
      </Modal>

      {/* Confirmation Prompt Modal */}
      <RedemptionConfirmationPrompt
        isOpen={isPromptOpen}
        onClose={() => setIsPromptOpen(false)}
        onConfirm={handleConfirmRedeem}
      />
    </>
  );
};

export default VoucherDetailsModal;
