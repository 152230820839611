import React, {useEffect, useState} from 'react';
import {Box, Image, Text, useTheme, Button} from '@chakra-ui/react';
import VoucherDetailsModal from './VoucherDetailsModal';
import {TextG10, TextG14, TextG8} from '../../../components/typography';
import {useSearchParams} from 'react-router-dom';
import dayjs from 'dayjs';

interface VoucherCardProps {
  group: {
    transactionId?: string;
    itemName: string;
    picture?: string;
    quantity: number;
    placeName?: string;
    pickupLocation?: string;
    pickupId?: string;
    vouchers?: any;
  };
  onRedemptionOpen: (voucher: any) => void;
}

const VoucherCard: React.FC<VoucherCardProps> = ({group, onRedemptionOpen}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const [transactionId, setTransactionId] = useState<string | null>();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const transaction = searchParams.get('transaction');
    if (transaction) {
      setTransactionId(transaction);

      searchParams.delete('transaction');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    setModalOpen(false);
  }, [group.transactionId]);

  useEffect(() => {
    if (transactionId && transactionId == group.transactionId) {
      setModalOpen(true);
    }
  }, [transactionId]);

  return (
    <Box
      key={group.transactionId || `no-transaction-${group.itemName}`}
      borderWidth="1px"
      borderRadius="lg"
      p={3}
      shadow="md"
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={4}
      onClick={() => setModalOpen(true)}>
      {/* Voucher Modal */}
      <VoucherDetailsModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        group={group}
        onRedemptionOpen={onRedemptionOpen}
      />

      {/* Image Section */}
      <Box
        flexShrink={0}
        boxSize="80px"
        borderRadius="lg"
        overflow="hidden"
        bg="transparent"
        display="flex"
        alignItems="center"
        justifyContent="center">
        {group.picture ? (
          <Image src={group.picture} alt={group.itemName} boxSize="100%" objectFit="cover" />
        ) : (
          <TextG10 fontSize="sm" color="gray.500">
            No Image
          </TextG10>
        )}
      </Box>

      {/* Content Section */}
      <Box display="flex" gap="6px" flexDir="column">
        {/* Item Name */}
        <TextG14 fontWeight={700} color={theme.colors.lightBlack} mb="6px">
          {group.itemName}
        </TextG14>

        {/* Place Name */}
        {group.placeName && <TextG10 color={theme.colors.lightBlack}>{group.placeName}</TextG10>}

        {/* Pickup Location */}
        {group.pickupLocation && !group.vouchers[0]?.redeemed_at && (
          <TextG10
            borderRadius="4px"
            px="6px"
            py="2px"
            background={theme.colors.blueShade2}
            color={theme.colors.blue}
            fontWeight="500">
            Pick-up @ <span style={{fontWeight: '700'}}>{group.pickupLocation}</span>
          </TextG10>
        )}

        {/* Quantity */}
        <TextG8 color={theme.colors.lightBlack}>Quantity: {group.quantity}</TextG8>

        {group.vouchers && group.vouchers[0]?.redeemed_at && (
          <TextG10 color={theme.colors.red}>{`Redeemed on ${dayjs
            .utc(group.vouchers[0]?.redeemed_at)
            .local()
            .format('MMM. D [at] h:mmA')}`}</TextG10>
        )}
      </Box>
    </Box>
  );
};

export default VoucherCard;
