import {Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import BackButton from '../../../components/BackButton';
import {TextA18} from '../../../components/typography';
import {Event} from '../../../types/acrticle';

const Header = ({type, item, event}: {type?: string; item?: any; event?: Event}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const backClicked = () => {
    if (type == 'ticket' && event) {
      navigate(`/event/${event.id}/tickets`, {replace: true});
      return;
    }

    if (type == 'pass' && item) {
      navigate(`/pass/${item.id}/purchase`, {replace: true});
      return;
    }

    navigate(-1);
  };
  return (
    <Flex background={theme.colors.veryLightBlue} px={3} py={5} align="center" w="100%">
      <BackButton onClick={backClicked} />
      <TextA18 fontWeight="700" w="100%" textAlign="center" marginLeft="-18px">
        Checkout
      </TextA18>
    </Flex>
  );
};

export default Header;
