import {Button, Flex} from '@chakra-ui/react';
import {filter, map} from 'lodash';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import {useFetchActivePasses} from '../../../hooks/useUserPasses';
import {TextG10} from '../../typography';

const UseablePassPill = ({event}) => {
  const {user} = useAuth();
  const {data, isLoading} = useFetchActivePasses(!!user);
  const navigate = useNavigate();

  const userPassesIds = map(
    filter(data?.data, (item) => item.pass !== null),
    (item) => item.pass.type_id
  );
  const passIds = map(event.pass_types, (item) => item.id);

  const shouldShow = !user?.hasActiveMembership
    ? true
    : passIds.some((id) => userPassesIds.includes(id));

  if (
    isLoading ||
    !shouldShow ||
    !event.pass_types ||
    event?.pass_types.length < 0 ||
    !event?.pass_types[0] ||
    !event?.has_tickets
  )
    return null;

  return (
    <Flex
      justifyContent="center"
      textAlign="center"
      maxW={user?.hasActiveMembership ? '190px' : '240px'}
      alignItems="baseline"
      px={1.5}
      py={0.5}
      borderRadius="4px"
      background={user?.hasActiveMembership ? '#E0F3E3' : '#E7F4FF'}>
      {user?.hasActiveMembership && (
        <i
          className="fi fi-br-check"
          style={{fontSize: '8px', color: '#329241', marginRight: '0.3rem'}}></i>
      )}

      {user?.hasActiveMembership ? (
        <TextG10 fontWeight="500" color="#329241">
          You can use a pass for this event
        </TextG10>
      ) : (
        <span style={{display: 'inline-flex', gap: '3px', color: '#276ACD'}}>
          <Button h="auto !important" p="0 !important" onClick={() => navigate('/memberships')}>
            <TextG10 fontWeight="700">{`Paid Members`}</TextG10>
          </Button>
          <TextG10 fontWeight="400">{` can use a pass for this event`}</TextG10>
        </span>
      )}
    </Flex>
  );
};

export default UseablePassPill;
