import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Image,
  Flex,
  Box,
  useTheme,
} from '@chakra-ui/react';
import {TextA18, TextA24, TextG14} from '../../../components/typography';
import {BlueButton} from '../../../components/button';

interface RedeemedVoucherModalProps {
  isOpen: boolean;
  onClose: () => void;
  itemName?: string;
  itemQuantity?: number;
  storeName?: string;
  code?: string;
  imageUrl?: string;
}

const RedeemedVoucherModal: React.FC<RedeemedVoucherModalProps> = ({
  isOpen,
  onClose,
  itemName,
  itemQuantity = 1,
  storeName,
  code,
  imageUrl,
}) => {
  const theme = useTheme();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          textAlign="center"
          fontWeight="700"
          color={theme.colors.lightBlack}
          pb="0 !important">
          <TextA18>{storeName}</TextA18>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" align="center" textAlign="center">
            <TextA24 mt={2} fontWeight="700" color={theme.colors.rose}>
              {itemName}
            </TextA24>
            <TextG14 mb={2} color={theme.colors.lightBlack}>
              Quantity: {itemQuantity}
            </TextG14>
            <Image
              src={imageUrl}
              alt={itemName}
              boxSize="150px"
              objectFit="cover"
              borderRadius="10px"
            />
            <Box
              mt={4}
              padding="10px"
              border="1px dashed"
              borderColor={theme.colors.grey}
              borderRadius="10px"
              width="80%">
              <Flex flexDir="column" gap="18px">
                <TextG14 color={theme.colors.lightBlack} fontWeight="700">
                  Present this code at checkout
                </TextG14>
                <TextA18 fontWeight="700" color="#329241">
                  {code}
                </TextA18>
              </Flex>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <BlueButton w="80%" onClick={onClose}>
            Done
          </BlueButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RedeemedVoucherModal;
