import {Offer} from '../types/acrticle';

export const getDisplayPrice = (
  price: number,
  max_price?: number | null,
  price_override?: string | null
) => {
  if (price_override) {
    return price_override;
  }

  if (max_price && max_price !== price) {
    return `${numberToCurrency(price)} - ${numberToCurrency(max_price)}`;
  }

  if (isFree(price)) {
    return 'FREE';
  }

  return `${numberToCurrency(price)}`;
};

export const isFree = (price: number) => {
  return price == 0;
};

export const getPointsToDollarRatio = (item) => {
  if (
    !item ||
    typeof item.retail_price !== 'string' ||
    typeof item.points_redeem_amount !== 'number'
  ) {
    throw new Error('Invalid item. Ensure both retail_price and points_redeem_amount are strings.');
  }

  // Convert strings to numbers
  const retailPrice = parseFloat(item.retail_price);
  const pointsRedeemAmount = item.points_redeem_amount;

  if (isNaN(retailPrice) || isNaN(pointsRedeemAmount)) {
    throw new Error(
      'Invalid numeric values in strings. Ensure retail_price and points_redeem_amount can be parsed as numbers.'
    );
  }

  // Prevent division by zero
  if (retailPrice === 0) {
    throw new Error('Retail price cannot be zero.');
  }

  // Calculate the ratio
  return retailPrice / pointsRedeemAmount;
};

export const dollarsToPoints = (currency, pointRatio) => currency / pointRatio;
export const pointsToDollars = (pointAmount, pointRatio) => (pointAmount * pointRatio).toFixed(2);
export const renderPointsToDollars = (pointAmount, pointRatio) =>
  numberToCurrency(pointsToDollars(pointAmount, pointRatio));
export const numberToCurrency = (num) => {
  const formatted = Number(num).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  // If the number has a ".00", remove the trailing zeros; otherwise, return as is
  return formatted.endsWith('.00')
    ? formatted.slice(0, -3) // Remove the ".00" part
    : formatted;
};

export function renderPointRatio(ratio) {
  // Calculate points needed for $1
  const points = 1 / ratio;

  // Format the result as 'X points = $1'
  return `${points} points = $1`;
}
