import React from 'react';
import Header from './Header';
import Carousel from './Carousel';
import CategoryGrid from './CategoryGrid';

const MarketplacePage: React.FC = () => (
  <>
    <Header />
    <Carousel />
    <CategoryGrid />
  </>
);

export default MarketplacePage;
