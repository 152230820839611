import React from 'react';
import {Stack, HStack, useTheme, StyleProps} from '@chakra-ui/react';
import {TextA14, TextG10} from '../typography';
import SwiperSlider, {SwiperSliderProps} from '../SwiperSlider';

interface ItemCarosuelProps<T> extends StyleProps {
  id?: string;
  highlightId?: string;
  title?: string;
  subtitle?: string;
  topRightItem?: any;
  swiperProps?: SwiperSliderProps<T>;
}

function ItemCarousel<T>({
  id,
  highlightId,
  title,
  subtitle,
  topRightItem,
  swiperProps,
  ...rest
}: ItemCarosuelProps<T>) {
  const theme = useTheme();

  if (!swiperProps?.items || swiperProps.items.length === 0) return null;

  return (
    <Stack
      id={highlightId}
      pos="relative"
      gap={title || topRightItem ? '.1rem' : 0}
      textAlign="left"
      width="100%"
      {...(rest as StyleProps)}>
      {topRightItem && (
        <HStack pos="absolute" top={2} right={3}>
          {topRightItem}
        </HStack>
      )}
      {(title ||
        subtitle) && (
          <Stack>
            {title && (
              <TextA14 id={id} fontWeight="bold" mb="5px">
                {title.toLocaleUpperCase()}
              </TextA14>
            )}
            {subtitle && (
              <TextG10 mt="0px !important" color={theme.colors.lightestGrey}>
                {subtitle}
              </TextG10>
            )}
          </Stack>
        )}
      <SwiperSlider {...(swiperProps as SwiperSliderProps<T>)} />
    </Stack>
  );
}

export default ItemCarousel;
