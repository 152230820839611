import {FILTER_TYPES, TAG_TYPE} from "../../../../utils/GlobalSearch/enums";
import {fetchTagCategories, fetchTags} from "../../../../utils/GlobalSearch/fetchOptions";

const OFFER_CONFIG = {
  offer: {
    pointsEligible: {
      key: 'pointsEligible',
      label: 'Points Eligible',
      type: FILTER_TYPES.TOGGLE,
      quick: false,
    },
    passEligible: {
      key: 'passEligible',
      label: 'Pass Eligible',
      type: FILTER_TYPES.TOGGLE,
      quick: false,
    },
    categories: {
      key: 'categories',
      label: 'Categories',
      type: FILTER_TYPES.MULTISELECT,
      quick: true,
      fetchOptions: fetchTagCategories,
    },
    topics: {
      key: 'tags',
      label: 'Interests',
      type: FILTER_TYPES.MULTISELECT,
      valueType: TAG_TYPE.TOPICS,
      quick: false,
      fetchOptions: fetchTags,
    },
    goals: {
      key: 'tags',
      label: 'Interests',
      type: FILTER_TYPES.MULTISELECT,
      valueType: TAG_TYPE.GOALS,
      quick: false,
      fetchOptions: fetchTags,
    },
  },
};

export default OFFER_CONFIG;