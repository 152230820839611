import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Center, Spinner, useTheme} from '@chakra-ui/react';
import TicketCheckout from './TicketCheckout';
import ItemCheckout from './PaymentOptions/ItemCheckout';
// import PassCheckout from './PassCheckout';
// import GenericItemCheckout from './GenericItemCheckout';

const CheckoutPage = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [itemType, setItemType] = useState<string | null>(null);

  useEffect(() => {
    const type = searchParams.get('type'); // `type=ticket|pass|item`
    setItemType(type);
  }, [searchParams]);

  if (!itemType) {
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  switch (itemType) {
    case 'ticket':
      return <TicketCheckout />;
    // case 'pass':
    //   return <PassCheckout />;
    default:
      return <ItemCheckout />;
  }

  return null;
};

export default CheckoutPage;
