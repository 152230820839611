import {Box, HStack, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {TextG12, TextG14} from '../../../components/typography';

const OrderDetails = ({item}) => {
  const theme = useTheme();
  const {qty} = useSelector((state: any) => state.checkout);

  return (
    <Box w="100%" px={3} background={theme.colors.veryLightBlue} margin="0px !important">
      <HStack justifyContent={'space-between'} pb={4}>
        <TextG14 p={1} dangerouslySetInnerHTML={{__html: item.title || item.name}} fontWeight="700">
          {}
        </TextG14>
        <TextG12 fontWeight="500" sx={{wordWrap: 'normal !important', whiteSpace: 'nowrap'}}>
          {`Qty: ${qty}`}
        </TextG12>
      </HStack>
    </Box>
  );
};

export default OrderDetails;
