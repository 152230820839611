import React, {useEffect, useState} from 'react';
import CheckoutDetails from '../../CheckoutDetails';
import OrderSummary from '../../OrderSummary';
import {useDispatch, useSelector} from 'react-redux';
import {
  setTotal,
  setAmountDue,
  reset,
  setQty,
  setMembershipDiscountPercentage,
} from '../../../../state/Checkout/CheckoutSlice';
import {useSearchParams} from 'react-router-dom';
import {MAX_TICKET_QTY} from '../../../TicketSelect/TicketQuantityContainer';
import {useFetchOffer} from '../../../../hooks/useOffers';
import {Center, Spinner, useTheme} from '@chakra-ui/react';
import useAuth from '../../../../hooks/useAuth';
import {getPointsToDollarRatio} from '../../../../utils/price';
import {useFetchActivePasses} from '../../../../hooks/useUserPasses';
import {filter, some} from 'lodash';

const ItemCheckout = () => {
  const theme = useTheme();
  const [itemId, setItemId] = useState<string | null>(null);
  const [showSummary, setShowSummary] = useState(false);
  const {data, isLoading} = useFetchOffer(itemId);
  const item = data?.data;

  const fetchActivePasses = useFetchActivePasses();
  const userPasses = fetchActivePasses.data?.data;

  const eligibleUserPasses = filter(userPasses, (userPass) =>
    some(item?.pass_types, (passType) => passType.id === userPass?.pass?.type?.id)
  );

  const [searchParams] = useSearchParams();

  const {user} = useAuth();
  const discountPerc = user?.activeMembershipPlan?.event_discount || 0;

  const {qty} = useSelector((state: any) => state.checkout);
  const dispatch = useDispatch();

  const passType = item?.pass_types?.length > 0 && item?.pass_types[0];
  // useEffect(() => {
  //   if (item && item?.retail_price) {
  //     dispatch(setPricePerQty(item?.retail_price));
  //   }
  // }, [item, item?.retail_price, dispatch]);

  useEffect(() => {
    if (!!searchParams.get('qty')) {
      dispatch(setQty(Math.min(parseInt(searchParams.get('qty') || '1'), MAX_TICKET_QTY)));
    }

    if (!!searchParams.get('item')) {
      setItemId(searchParams.get('item'));
    }
  }, [searchParams]);

  // useEffect(() => {
  //   if (qty && item?.retail_price) {
  //     dispatch(setPricePerQty(item?.retail_price));
  //     const newTotal = parseFloat(item?.retail_price) * qty;
  //     dispatch(setTotal(newTotal));
  //     dispatch(setAmountDue(newTotal));
  //   }
  // }, [item, qty, dispatch]);

  useEffect(() => {
    if (qty && item && item?.retail_price) {
      const newTotal = item.retail_price * qty;
      dispatch(reset());
      if (!!searchParams.get('qty')) {
        dispatch(setQty(Math.min(parseInt(searchParams.get('qty') || '1'), MAX_TICKET_QTY)));
      }

      dispatch(setTotal(newTotal));
      dispatch(setAmountDue(newTotal));
      dispatch(setMembershipDiscountPercentage(discountPerc));
    }
  }, [qty, item, item?.retail_price, discountPerc, dispatch]);

  if (!item)
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  const pointCost =
    item.retail_price == 0 && item.points_redeem_amount == 0
      ? '0'
      : getPointsToDollarRatio(item)?.toString();
  const retailCost = item.retail_price;

  const cardEnabled = item?.item_payment_cc;
  const pointsEnabled = item?.item_payment_points;
  const passEnabled = item?.item_payment_pass;

  return showSummary ? (
    <OrderSummary
      type="item"
      item={item}
      setShowSummary={setShowSummary}
      pointCost={pointCost}
      retailCost={retailCost}
    />
  ) : (
    //   <OrderSummary ticket={ticket} event={event} setShowSummary={setShowSummary} />
    <CheckoutDetails
      type="item"
      passType={passType}
      cardEnabled={cardEnabled}
      pointsEnabled={pointsEnabled}
      passEnabled={passEnabled}
      pointCost={pointCost}
      retailCost={retailCost}
      eligibleUserPasses={eligibleUserPasses}
      item={item}
      //event={undefined}
      setShowSummary={setShowSummary}
    />
    // <CheckoutDetails
    //   type="item"
    //   cardEnabled={cardEnabled}
    //   pointsEnabled={pointsEnabled}
    //   passEnabled={passEnabled}
    //   pointCost={pointCost}
    //   retailCost={retailCost}
    //   item={item}
    //   setShowSummary={setShowSummary}
    // />
  );
};

export default ItemCheckout;
