import React from 'react';
import {Box, Button, Flex, Image, Tag, Text, VStack} from '@chakra-ui/react';
import {TextA32, TextG14} from '../../components/typography';
import useAuth from '../../hooks/useAuth';
import {getMembershipGradient} from '../../utils/membership';
import BackButton from '../../components/BackButton';
import MembershipPill from '../../components/MembershipPill';
import {useNavigate} from 'react-router-dom';
import Icon from '../../components/Icon';

const Header = () => {
  const {user} = useAuth();
  const navigate = useNavigate();

  const membership = user?.activeMembershipPlan;

  return (
    <>
      <Box
        position="absolute"
        top="0px"
        background="linear-gradient(180deg, #6361EC 0%, #E5D8FC 100%);"
        height="270px"
        zIndex="-1"
        w="100vw"
      />
      <Flex
        background="transparent"
        gap="8px"
        width="100%"
        px={5}
        pb="0"
        pt="30px"
        flexDirection={'column'}>
        <Flex justifyContent="space-between" align="center">
          <BackButton onClick={() => navigate(-1)} />
          <MembershipPill overrideMembership={membership} />
          <Icon
            onClick={() => navigate('/home')}
            iconName={'fi-rr-home'}
            style={{
              fontSize: '20px',
              margin: '0 !important',
              width: '35px',
              textAlign: 'center',
              color: 'white',
            }}
          />
        </Flex>
        <VStack mt={2} alignItems="flex-start" color="white">
          <TextA32>MARKETPLACE</TextA32>
          <TextG14>Discover Handpicked Local Items and Experiences Nearby</TextG14>
        </VStack>
      </Flex>
    </>
  );
};

export default Header;

//  <Box bg="purple.500" color="white" py={6} px={4}>
//     <Flex justify="space-between" align="center">
//       <Button size="sm" variant="ghost" colorScheme="whiteAlpha">
//         &larr;
//       </Button>
//       <Tag bg="purple.300" color="white" borderRadius="full" px={4}>
//         Local Lifestyle Member
//       </Tag>
//       <Flex>
//         <Image
//           src="https://via.placeholder.com/30"
//           alt="Profile"
//           borderRadius="full"
//           boxSize="30px"
//           border="2px solid white"
//           ml="-10px"
//         />
//         <Image
//           src="https://via.placeholder.com/30"
//           alt="Profile"
//           borderRadius="full"
//           boxSize="30px"
//           border="2px solid white"
//         />
//       </Flex>
//     </Flex>
//     <TextA32 color="white" mt={4}>
//       MARKETPLACE
//     </TextA32>
//     <TextG14 color="white">Discover Handpicked Local Items and Experiences Nearby</TextG14>
//   </Box>
