import React from 'react';
import {Heading, Text} from '@chakra-ui/react';
import {browserDetected, isMobile} from '../utils/deviceCheck';

const shouldAddMargin = () => {
  if (process.env.NODE_ENV === 'production') return browserDetected() && !isMobile;
  else return true;
};

export function H1({children, ...props}) {
  return (
    <Heading
      as="h1"
      fontSize="32px"
      lineHeight="38px"
      fontFamily="Aileron"
      fontWeight="bold"
      {...props}>
      {children}
    </Heading>
  );
}

export function H2({children, ...props}) {
  return (
    <Heading
      as="h2"
      fontSize="24px"
      lineHeight="28px"
      fontFamily="Aileron"
      fontWeight="900"
      {...props}>
      {children}
    </Heading>
  );
}

export function H3({children, ...props}) {
  return (
    <Heading
      as="h3"
      fontSize="18px"
      lineHeight="22px"
      fontFamily="Aileron"
      fontWeight="700"
      {...props}>
      {children}
    </Heading>
  );
}

export function TextG8({children, ...props}) {
  return (
    <Text
      mt={shouldAddMargin() ? '0' : '0.25rem'}
      fontSize="8px"
      lineHeight="12px"
      fontFamily="Gordita"
      {...props}>
      {children}
    </Text>
  );
}

export function TextG9({children, ...props}) {
  return (
    <Text
      mt={shouldAddMargin() ? '0' : '0.25rem'}
      fontSize="9px"
      lineHeight="13px"
      fontFamily="Gordita"
      {...props}>
      {children}
    </Text>
  );
}

export function TextG10({children, ...props}) {
  return (
    <Text
      mt={shouldAddMargin() ? '0' : '0.25rem'}
      fontSize="10px"
      lineHeight="14px"
      fontFamily="Gordita"
      {...props}>
      {children}
    </Text>
  );
}

export function TextG11({children, ...props}) {
  return (
    <Text
      mt={shouldAddMargin() ? '0' : '0.25rem'}
      fontSize="11px"
      lineHeight="15px"
      fontFamily="Gordita"
      {...props}>
      {children}
    </Text>
  );
}

export function TextG12({children, ...props}) {
  return (
    <Text
      mt={shouldAddMargin() ? '0' : '0.25rem'}
      fontSize="12px"
      lineHeight="16px"
      fontFamily="Gordita"
      {...props}>
      {children}
    </Text>
  );
}

export function TextA8({children, ...props}) {
  return (
    <Text fontSize="8px" lineHeight="10px" fontFamily="Aileron" {...props}>
      {children}
    </Text>
  );
}

export function TextA9({children, ...props}) {
  return (
    <Text fontSize="9px" lineHeight="12px" fontFamily="Aileron" {...props}>
      {children}
    </Text>
  );
}

export function TextA10({children, ...props}) {
  return (
    <Text fontSize="10px" lineHeight="14px" fontFamily="Aileron" {...props}>
      {children}
    </Text>
  );
}

export function TextA12({children, ...props}) {
  return (
    <Text fontSize="12px" lineHeight="16px" fontFamily="Aileron" {...props}>
      {children}
    </Text>
  );
}

export function TextG14({children, ...props}) {
  return (
    <Text
      mt={shouldAddMargin() ? '0' : '0.25rem'}
      fontSize="14px"
      lineHeight="20px"
      fontFamily="Gordita"
      {...props}>
      {children}
    </Text>
  );
}

export function TextG16({children, ...props}) {
  return (
    <Text
      mt={shouldAddMargin() ? '0' : '0.25rem'}
      fontSize="16px"
      lineHeight="20px"
      fontFamily="Gordita"
      {...props}>
      {children}
    </Text>
  );
}

export function TextG18({children, ...props}) {
  return (
    <Text
      mt={shouldAddMargin() ? '0' : '0.25rem'}
      fontSize="18px"
      lineHeight="22px"
      fontFamily="Gordita"
      {...props}>
      {children}
    </Text>
  );
}

export function TextG20({children, ...props}) {
  return (
    <Text
      mt={shouldAddMargin() ? '0' : '0.25rem'}
      fontSize="20px"
      lineHeight="24px"
      fontFamily="Gordita"
      {...props}>
      {children}
    </Text>
  );
}

export function TextG24({children, ...props}) {
  return (
    <Text
      mt={shouldAddMargin() ? '0' : '0.25rem'}
      fontSize="24px"
      lineHeight="32px"
      fontFamily="Gordita"
      {...props}>
      {children}
    </Text>
  );
}

export function TextA14({children, ...props}) {
  return (
    <Text fontSize="14px" lineHeight="20px" fontFamily="Aileron" fontWeight="400" {...props}>
      {children}
    </Text>
  );
}

export function TextA16({children, ...props}) {
  return (
    <Text fontSize="16px" lineHeight="21px" fontFamily="Aileron" fontWeight="700" {...props}>
      {children}
    </Text>
  );
}

export function TextA18({children, ...props}) {
  return (
    <Text fontSize="18px" lineHeight="22px" fontFamily="Aileron" fontWeight="700" {...props}>
      {children}
    </Text>
  );
}

export function TextA20({children, ...props}) {
  return (
    <Text fontSize="20px" lineHeight="24px" fontFamily="Aileron" fontWeight="700" {...props}>
      {children}
    </Text>
  );
}

export function TextA24({children, ...props}) {
  return (
    <Text fontSize="24px" lineHeight="32px" fontFamily="Aileron" fontWeight="700" {...props}>
      {children}
    </Text>
  );
}

export function TextA32({children, ...props}) {
  return (
    <Text fontSize="32px" lineHeight="40px" fontFamily="Aileron" fontWeight="700" {...props}>
      {children}
    </Text>
  );
}

