import React from 'react';
import {Box, Grid, Image, useTheme, VStack} from '@chakra-ui/react';
import {TextA18} from '../../components/typography';
import FoodImage from '../../assets/images/marketplace/food.png';
import ExperiencesImage from '../../assets/images/marketplace/experiences.png';
import DropsImage from '../../assets/images/marketplace/drops.png';
import MerchImage from '../../assets/images/marketplace/merch.png';
import ServicesImage from '../../assets/images/marketplace/services.png';
import {useTagCategories} from '../../hooks/useGlobalSearch';
import {useNavigate} from 'react-router-dom';

const CategoryGrid: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {data, isLoading, isError} = useTagCategories();
  const tagCategories = data?.data;

  const categories = [
    {title: 'Food & Beverage', image: FoodImage, textColor: '#C53030'},
    {title: 'Experiences', image: ExperiencesImage, textColor: '#2158AB'},
    {title: 'Exclusive Drops', image: DropsImage, textColor: '#BB276E'},
    {title: 'Merch', image: MerchImage, textColor: '#DD6B20'},
    {title: 'Services', image: ServicesImage, textColor: '#25855A'},
  ].map((category) => {
    const matchingCategory = tagCategories?.find((tag) => tag.name === category.title);
    return {
      ...category,
      id: matchingCategory?.id || null, // Add the id attribute
    };
  });

  return (
    <VStack spacing={4} px={4} pb={4}>
      <Grid
        templateColumns="repeat(2, 1fr)" // Keeps two cards per row
        justifyContent="space-between" // Ensures items are pushed to the sides with space in between
        rowGap="15px" // Spacing between rows
        columnGap="15px"
        w="100%">
        {categories.map((category, index) => (
          <Box
            key={index}
            bg="white"
            borderRadius="10px"
            overflow="hidden"
            shadow="md"
            w="100%"
            onClick={() =>
              navigate(
                `/standalone-search/offer?filterTitle=${encodeURIComponent(
                  category.title
                )}&back=1&categories=${category.id}&by=ends_at&direction=asc`
              )
            }>
            <Image src={category.image} alt={category.title} w="100%" h="140px" objectFit="cover" />
            <TextA18 fontWeight="800" textAlign="center" py={2} color={category.textColor}>
              {category.title}
            </TextA18>
          </Box>
        ))}
      </Grid>
    </VStack>
  );
};

export default CategoryGrid;
