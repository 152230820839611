import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {fetchItemVouchers, redeemItemVoucher} from '../endpoints/api';

export const useFetchItemVouchers = (showUsedVouchers = 0) => {
  return useQuery(['user_vouchers', showUsedVouchers], () => fetchItemVouchers(showUsedVouchers));
};

export const useItemRedeemVoucher = () => {
  const queryClient = useQueryClient();
  return useMutation((voucherId) => redeemItemVoucher(voucherId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['user_vouchers']);
    },
  });
};
