import React, {useMemo} from 'react';
import {border, Box, Image, useTheme} from '@chakra-ui/react';
import styled from 'styled-components';
import FallbackImg from '../../assets/images/fallback.jpg';
import ExclusiveCommunityLabel from '../ExclusiveCommunityLabel';
import {toLower} from 'lodash';
import useTrackEvent from '../../hooks/useTrackEvent';
import dayjs from 'dayjs';

// const Cover = styled(Image)`
//   width: 100%;
//   height: 200px;
//   border-top-left-radius: 5px;
//   border-top-right-radius: 5px;
//   object-fit: cover;
// `;

const Cover = styled(Image)`
  width: 100%;
  height: 150px;
  object-fit: cover;
  top: 0;
  left: 0;
  border-radius: ${(props) => props.borderRadius} ${(props) => props.borderRadius} 0px 0px !important;
`;

const ChildContainer = styled(Box)`
  width: 100%;
  height: fit-content;
  background: white;
  color: black;
  position: relative;
  bottom: 0;
  left: 0;
  border-radius: ${(props) => props.borderRadius};
`;

const ImageOverlay = styled(Box)`
  width: 100%;
  height: ${(props) =>
    props.$overlayBackgroundColor && props.$overlayHeight ? props.$overlayHeight : '100%'};
  ${(props) =>
    props.$overlayBackgroundColor
      ? `background-color: ${props.$overlayBackgroundColor};`
      : `background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.33) ${props.$overlayHeight},
    transparent ${props.$overlayTransparency}
  );`}
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: ${(props) => props.borderRadius};
`;

const Card = ({
  children,
  coverImage,
  leftTopItem,
  rightTopItem,
  childPaddingX,
  childPaddingY,
  height,
  overlayHeight = '33%',
  overlayTransparency = '66%',
  onClick,
  borderRadius = '12px',
  overlayBackgroundColor = '',
  childTop = '',
  isSlide = false,
  article,
  outline,
}) => {
  const theme = useTheme();
  const isExclusive = article?.communities?.length > 0 && article.is_private;
  const hasCardBadge = article?.is_walkabout_official || article?.partner;
  const {addUserEvent} = useTrackEvent();

  return (
    <Box w="100%" mt={isExclusive ? '-8px' : 0}>
      {isExclusive ? (
        <ExclusiveCommunityLabel
          community={article.communities[0]}
          isSlide={isSlide}
          hasCardBadge={hasCardBadge}
        />
      ) : null}
      <Box
        outline={outline}
        background="white"
        width="100%"
        // width={`${isSlide ? '97.5%' : '100%'}`}
        // ml={`${isSlide ? '1.25%' : '0%'}`}
        position="relative"
        height={height}
        borderRadius={borderRadius}
        onClick={(e) => {
          onClick ? onClick(e) : null;

          const articleType = article.is_meetup
            ? 'meetup'
            : article.type?.name
            ? 'place'
            : toLower(article.type);

          const articleId = article.id;

          addUserEvent({
            type: 'click',
            event_name: 'card_click',
            event_time: dayjs().format(),
            data: {itemType: articleType, itemId: articleId},
          });
        }}
        boxShadow="0px 2px 8px 0px #00000026">
        <Cover
          borderRadius={borderRadius}
          fallbackStrategy="onError"
          loading={null}
          fallbackSrc={FallbackImg}
          src={coverImage}
          alt={'Cover image'}
        />
        {leftTopItem}
        {rightTopItem}
        {/* {children} */}
        <ChildContainer
          top={childTop || '0px'}
          borderRadius={`0px 0px ${borderRadius} ${borderRadius}`}>
          <Box px={3} pt={1} pb={3}>
            {children}
          </Box>
        </ChildContainer>
      </Box>
    </Box>
  );
};

Card.defaultProps = {
  coverImage: null,
  leftTopItem: null,
  rightTopItem: null,
  childPaddingX: 4,
  childPaddingY: 3,
  height: 'auto',
  outline: null,
  onClick: () => null,
};

export default Card;
