import {
  Center,
  Flex,
  Spinner,
  Switch,
  useTheme,
  AbsoluteCenter,
  Divider,
  Spacer,
  Container,
} from '@chakra-ui/react';
import React, {useMemo, useState} from 'react';
import {H3, TextA18, TextA24, TextG10, TextG12} from '../../../components/typography';
import {useFetchItemVouchers} from '../../../hooks/useItemVouchers';
import RedeemedVoucherModal from './RedeemedVoucherModal';
import VoucherCard from './VoucherCard';

const Vouchers = () => {
  const theme = useTheme();
  const [showUsedVouchers, setShowUsedVouchers] = useState(false);
  const [redeemedVouchOpen, setRedeemedVouchOpen] = useState(false);
  const [redeemedVoucher, setRedeemedVoucher] = useState<any>();

  const onRedemptionOpen = (voucher: any) => {
    setRedeemedVoucher(voucher);
    setRedeemedVouchOpen(true);
  };

  const toggleUsedVouchers = () => {
    setShowUsedVouchers((prevState) => !prevState);
  };

  const {data, isLoading, isError} = useFetchItemVouchers(+showUsedVouchers);
  const vouchers = data?.data.vouchers;

  // if (isLoading || !vouchers) {
  //   return (
  //     <Center h="100vh">
  //       <Spinner
  //         thickness="4px"
  //         speed="0.65s"
  //         emptyColor={theme.colors.lightGrey}
  //         color={theme.colors.lightBlue}
  //         size="lg"
  //       />
  //     </Center>
  //   );
  // }

  if (isError) {
    return (
      <Center mt={10}>
        <TextG12 color="red.500">Failed to load vouchers. Please try again later.</TextG12>
      </Center>
    );
  }

  // // Group vouchers by transaction_id
  // const groupedVouchers = Object.values(
  //   vouchers.reduce((acc, voucher) => {
  //     const {transaction_id} = voucher;
  //     if (!acc[transaction_id]) {
  //       acc[transaction_id] = [];
  //     }
  //     acc[transaction_id].push(voucher);
  //     return acc;
  //   }, {})
  // ).map((group: any) => ({
  //   itemName: group[0].item_name,
  //   picture: group[0].picture,
  //   quantity: group.length,
  //   transactionId: group[0].transaction_id,
  //   placeName: group[0].place_name,
  //   pickupLocation: group[0].place_name || group[0].event_name || null,
  //   pickupId: group[0].place_id || null,
  //   vouchers: group,
  // }));

  const groupedVouchers = useMemo(() => {
    if (!vouchers) return null;
    return Object.values(
      vouchers.reduce((acc, voucher) => {
        const {transaction_id} = voucher;
        if (!acc[transaction_id]) {
          acc[transaction_id] = [];
        }
        acc[transaction_id].push(voucher);
        return acc;
      }, {} as Record<string, any[]>)
    ).map((group: any) => ({
      itemName: group[0].item_name,
      picture: group[0].picture,
      quantity: group.length,
      transactionId: group[0].transaction_id,
      placeName: group[0].place_name,
      pickupLocation: group[0].place_name || group[0].event_name || null,
      pickupId: group[0].place_id || null,
      vouchers: group,
    }));
  }, [vouchers]);

  return (
    <Container pt={1} px={'0 !important'} pb={'initial'} h="auto">
      <Flex my={3}>
        <Center>
          <H3 color={theme.colors.darkGrey}>Vouchers</H3>
        </Center>
        <Spacer />
        <Flex align="center">
          <TextG10 fontWeight="500" mr={2} color={theme.colors.lightBlack}>
            Show Used Vouchers
          </TextG10>
          <Switch
            size="md"
            sx={{
              '& .chakra-switch__track': {
                boxShadow: 'none !important',
              },
              '& .chakra-switch__track:focus': {
                boxShadow: 'none !important',
              },
              '& .chakra-switch__track:focus-visible': {
                boxShadow: 'none !important',
              },
            }}
            isChecked={showUsedVouchers}
            onChange={toggleUsedVouchers}
            colorScheme="green"
          />
        </Flex>
      </Flex>
      <Divider mb={3} />
      {isLoading || !vouchers ? (
        <AbsoluteCenter>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={theme.colors.lightGrey}
            color={theme.colors.lightBlue}
            size="lg"
          />
        </AbsoluteCenter>
      ) : (
        <Flex direction="column" gap={4} my="20px">
          {!groupedVouchers ||
            (groupedVouchers.length <= 0 ? (
              <AbsoluteCenter>
                <TextA24 textAlign="center">You currently have no vouchers</TextA24>
              </AbsoluteCenter>
            ) : (
              groupedVouchers &&
              groupedVouchers.map((group, i) => (
                <VoucherCard key={i} group={group} onRedemptionOpen={onRedemptionOpen} />
              ))
            ))}
        </Flex>
      )}
      <RedeemedVoucherModal
        isOpen={redeemedVouchOpen}
        onClose={() => setRedeemedVouchOpen(false)}
        itemName={redeemedVoucher?.item_name}
        storeName={redeemedVoucher?.place_name}
        code={redeemedVoucher?.code}
        imageUrl={redeemedVoucher?.picture}
      />
    </Container>
  );
};

export default Vouchers;
