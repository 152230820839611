import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';
import { BlueButton, WhiteButton } from '../../../components/button';
import { TextA24, TextG14 } from '../../../components/typography';

interface ConfirmationPromptModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  description?: string;
}

const ConfirmationPromptModal: React.FC<ConfirmationPromptModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title = 'Are you ready?',
  description = 'Once confirmed, this action cannot be undone. Please ensure you are ready to redeem the voucher.',
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <TextA24 textAlign="center" fontWeight="900">{title}</TextA24>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TextG14>{description}</TextG14>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="space-between">
            <WhiteButton variant="outline" colorScheme="gray" onClick={onClose} w="48%">
              Cancel
            </WhiteButton>
            <BlueButton onClick={onConfirm} w="48%">
              Confirm
            </BlueButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationPromptModal;
