import React from 'react';
import {Button, useTheme} from '@chakra-ui/react';
import Icon from '../../Icon';

const QuickFilter = ({
  children,
  isFilterOn,
  onClick,
  selectedColor,
  selectedBackground,
  selectedBorder,
}) => {
  const theme = useTheme();

  // Define default colors based on the theme
  const defaultSelectedColor = theme.colors.blue;
  const defaultSelectedBackground = theme.colors.blueShade2;
  const defaultSelectedBorderColor = theme.colors.blueShade1;

  return (
    <Button
      borderRadius="10px"
      borderColor={
        isFilterOn ? selectedBorder || defaultSelectedBorderColor : theme.colors.lightestGrey
      }
      borderWidth={'1px'}
      backgroundColor={`${
        isFilterOn ? selectedBackground || defaultSelectedColor : 'white'
      } !important`}
      color={`${
        isFilterOn ? selectedColor || defaultSelectedBackground : theme.colors.grey
      } !important`}
      onClick={onClick}
      w="fit-content"
      h="30px"
      p={2}
      fontSize={'10px'}
      alignItems={'center'}>
      {isFilterOn ? (
        <Icon
          style={{margin: '4px', alignSelf: 'flex-start', fontSize: '6px'}}
          iconName={'fi fi-rr-cross'}
        />
      ) : (
        <></>
      )}
      {children}
    </Button>
  );
};

export default QuickFilter;
