import React, {useEffect, useState} from 'react';
import {useFetchEvent} from '../../../hooks/useEvent';
import CheckoutDetails from '../CheckoutDetails';
import OrderSummary from '../OrderSummary';
import {useDispatch, useSelector} from 'react-redux';
import {
  setPricePerQty,
  setTotal,
  setAmountDue,
  reset,
  setQty,
  setMembershipDiscountPercentage,
} from '../../../state/Checkout/CheckoutSlice';
import {useSearchParams} from 'react-router-dom';
import {MAX_TICKET_QTY} from '../../TicketSelect/TicketQuantityContainer';
import {useFetchActivePasses} from '../../../hooks/useUserPasses';
import {filter, some} from 'lodash';
import useAuth from '../../../hooks/useAuth';
import {Center, Spinner, useTheme} from '@chakra-ui/react';

const TicketCheckout = () => {
  const theme = useTheme();
  const [eventId, setEventId] = useState<string | null>(null);
  const [ticket, setTicket] = useState<any>(null);
  const [showSummary, setShowSummary] = useState(false);
  const fetchEvent = useFetchEvent(Number(eventId));
  const event = fetchEvent.data?.data;

  const fetchActivePasses = useFetchActivePasses();
  const userPasses = fetchActivePasses.data?.data;

  const eligibleUserPasses = filter(userPasses, (userPass) =>
    some(event?.pass_types, (passType) => passType.id === userPass?.pass?.type?.id)
  );

  const {user} = useAuth();
  const discountPerc = user?.activeMembershipPlan?.event_discount || 0;

  const [searchParams] = useSearchParams();

  const {qty} = useSelector((state: any) => state.checkout);
  const dispatch = useDispatch();

  useEffect(() => {
    if (event?.available_tickets?.length) {
      setTicket(event.available_tickets[0]);
    }
  }, [event]);

  useEffect(() => {
    if (ticket && ticket?.price) {
      dispatch(setPricePerQty(ticket.price));
    }
  }, [ticket, ticket?.price, dispatch]);

  useEffect(() => {
    if (!!searchParams.get('qty')) {
      dispatch(setQty(Math.min(parseInt(searchParams.get('qty') || '1'), MAX_TICKET_QTY)));
    }

    if (!!searchParams.get('event')) {
      setEventId(searchParams.get('event'));
    }
  }, [searchParams]);

  // useEffect(() => {
  //   if (ticket?.price) {
  //     dispatch(setPricePerQty(ticket.price));
  //     const newTotal = parseFloat(ticket.price) * qty;
  //     dispatch(setTotal(newTotal));
  //     dispatch(setAmountDue(newTotal));
  //   }
  // }, [ticket, qty, dispatch]);

  useEffect(() => {
    if (qty && ticket && ticket?.price) {
      const newTotal = parseFloat(ticket.price.replace(/[^\d.-]/g, '')) * qty;
      dispatch(reset());
      if (!!searchParams.get('qty')) {
        dispatch(setQty(Math.min(parseInt(searchParams.get('qty') || '1'), MAX_TICKET_QTY)));
      }

      dispatch(setTotal(newTotal));
      dispatch(setAmountDue(newTotal));
      dispatch(setMembershipDiscountPercentage(discountPerc));
    }
  }, [qty, ticket, ticket?.price, discountPerc, dispatch]);

  if (!event || !ticket)
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  const pointCost = ticket.points_value;
  const retailCost = parseFloat(ticket.price.replace(/[^\d.-]/g, ''));

  const passType = event?.pass_types?.length > 0 && event?.pass_types[0];

  const cardEnabled = event?.ticket_payment_cc;
  const pointsEnabled = event?.ticket_payment_points;
  const passEnabled = event?.ticket_payment_pass;

  return showSummary ? (
    <OrderSummary type="ticket" item={ticket} event={event} setShowSummary={setShowSummary} />
  ) : (
    <CheckoutDetails
      type="ticket"
      passType={passType}
      cardEnabled={cardEnabled}
      pointsEnabled={pointsEnabled}
      passEnabled={passEnabled}
      pointCost={pointCost}
      retailCost={retailCost}
      eligibleUserPasses={eligibleUserPasses}
      item={ticket}
      event={event}
      setShowSummary={setShowSummary}
    />
  );
};

export default TicketCheckout;
